import { isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import packageInfo from '../../../package.json';
import 'projects/@core/utils/extension-methods';

const loadModule = async () => {
  const { isMobileDevice } = await import('./device.utils');

  if (isMobileDevice())
    import('projects/web-app/src/app/+mobile/app.module').then(m => {
      platformBrowserDynamic().bootstrapModule(m.AppModule, { ngZoneEventCoalescing: true })
        .catch(err => console.error(err));
    })
  else
    import('projects/web-app/src/app/+desktop/app.module').then(m => {
      platformBrowserDynamic().bootstrapModule(m.AppModule, { ngZoneEventCoalescing: true })
        .catch(err => console.error(err));
    })
}

if (isDevMode() && !window.noviApp)
  fetch('/apps/config')
    .then(response => response.json())
    .then((config: any) => {
      window.noviApp = {
        domain: config.domainKey,
        environment: config.environment,
        version: packageInfo.version
      }

      loadModule();
    });
else
  loadModule();

