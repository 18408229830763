String.prototype.toTimeSpanSeconds = function (): number {
  const values = String(this).split(':');
  let data = null;

  if (values.length > 3) {
    data = {
      days: +values[0],
      hours: +values[1],
      mins: +values[2],
      secs: Math.round(+values[3]),
    };
  } else {
    data = {
      days: 0,
      hours: +values[0],
      mins: +values[1],
      secs: Math.round(+values[2]),
    };
  }

  return data.days * 24 * 60 * 60 + data.hours * 60 * 60 + data.mins * 60 + data.secs;
};

String.prototype.format = function () {
  const args = arguments;
  return this.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != 'undefined'
      ? args[number]
      : match
      ;
  });
};
