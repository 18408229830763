{
  "name": "products.novi.web",
  "version": "4.61.1",
  "scripts": {
    "start:web": "ng serve web-app",
    "start:native": "ng serve native-app",
    "start:poker": "ng serve casino-poker-app",
    "start:proxy": "node ./tools/dev-proxy/main.js",
    "build": "ng build",
    "ng:run": "ng run",
    "build:web:github": "ng run web-app:build:production && ng run web-app:server:production",
    "build:native:github": "ng run native-app:build:production && ng run native-app:server:production",
    "build:poker:github": "ng run casino-poker-app:build:production && ng run casino-poker-app:server:production",
    "test:native:github": "ng test --project=native-app --watch=false --browsers=ChromeHeadless --code-coverage",
    "test:native": "ng test --project=native-app --code-coverage",
    "test:poker:github": "ng test --project=casino-poker-app --watch=false --browsers=ChromeHeadless --code-coverage",
    "test:poker": "ng test --project=casino-poker-app --code-coverage",
    "test:web:github": "ng test --project=unit-test-app --watch=false --browsers=ChromeHeadless --code-coverage",
    "test:web": "ng test --project=unit-test-app --code-coverage",
    "lint": "ng lint",
    "lint:fix": "ng lint --fix",
    "lint:styles": "stylelint **/*.scss",
    "lint:styles:fix": "stylelint **/*.scss --fix",
    "release": "standard-version",
    "release:patch": "npm run release -- --release-as patch",
    "release:minor": "npm run release -- --release-as minor",
    "release:major": "npm run release -- --release-as major",
    "release:draft": "npm run release -- --prerelease",
    "sprite-generator": "node ./tools/sprite-generator/generate.js",
    "bundle:report": "source-map-explorer dist/web-app/**/*.js",
    "generate-icons": "svg-to-ts-files",
    "generate-icons-preview": "node ./projects/@shared/assets/icons/build/build",
    "mocks": "mocks-server",
    "generate:octo:vars": "node ./tools/octopus_variables/generate-octopus-variables.mjs",
    "deploy:octo": "node ./tools/automatedOCBuild.js",
    "storybook": "ng run web-app:storybook",
    "build-storybook": "ng run web-app:build-storybook"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.4",
    "@angular/cdk": "^19.1.4",
    "@angular/common": "^19.1.4",
    "@angular/compiler": "^19.1.4",
    "@angular/core": "^19.1.4",
    "@angular/elements": "^19.1.4",
    "@angular/forms": "^19.1.4",
    "@angular/material": "^19.1.4",
    "@angular/platform-browser": "^19.1.4",
    "@angular/platform-browser-dynamic": "^19.1.4",
    "@angular/platform-server": "^19.1.4",
    "@angular/router": "^19.1.4",
    "@angular/ssr": "^19.1.4",
    "@fullstory/browser": "^1.7.1",
    "@microsoft/signalr": "^8.0.7",
    "@ngx-translate/core": "^15.0.0",
    "@ngxs/logger-plugin": "^19.0.0",
    "@ngxs/storage-plugin": "^19.0.0",
    "@ngxs/store": "^19.0.0",
    "@sentry/angular": "^8.47.0",
    "@transifex/api": "^7.1.3",
    "axios": "^1.7.9",
    "chart.js": "^4.4.7",
    "compression": "^1.7.5",
    "express": "^4.19.2",
    "firebase": "^11.1.0",
    "gsap": "2.1.3",
    "html-to-image": "^1.11.11",
    "http-proxy-middleware": "^3.0.3",
    "ng2-charts": "^4.1.1",
    "ngx-infinite-scroll": "^19.0.0",
    "ngx-sharebuttons": "^15.0.6",
    "prerender-node": "^3.8.0",
    "prom-client": "^15.1.3",
    "qrcode": "^1.5.4",
    "rxjs": "^7.8.1",
    "swiper": "^11.2.0",
    "tslib": "2.8.1",
    "winston": "^3.17.0",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.4",
    "@angular-eslint/builder": "19.0.2",
    "@angular-eslint/eslint-plugin": "19.0.2",
    "@angular-eslint/eslint-plugin-template": "19.0.2",
    "@angular-eslint/schematics": "19.0.2",
    "@angular-eslint/template-parser": "19.0.2",
    "@angular/cli": "^19.1.4",
    "@angular/compiler-cli": "^19.1.4",
    "@chromatic-com/storybook": "^1.9.0",
    "@compodoc/compodoc": "^1.1.25",
    "@mocks-server/main": "^4.1.0",
    "@ngxs/devtools-plugin": "^19.0.0",
    "@octopusdeploy/api-client": "^3.0.6",
    "@storybook/addon-docs": "^8.3.3",
    "@storybook/addon-essentials": "^8.3.3",
    "@storybook/addon-interactions": "^8.3.3",
    "@storybook/addon-links": "^8.3.3",
    "@storybook/addon-onboarding": "^8.3.3",
    "@storybook/angular": "^8.4.7",
    "@storybook/blocks": "^8.3.3",
    "@storybook/test": "^8.3.3",
    "@types/compression": "1.7.2",
    "@types/express": "^4.17.17",
    "@types/file-saver-es": "2.0.1",
    "@types/jasmine": "5.1.0",
    "@types/jasminewd2": "2.0.10",
    "@types/jquery": "3.5.14",
    "@types/node": "^20.10.4",
    "@types/qrcode": "^1.5.5",
    "@typescript-eslint/eslint-plugin": "^8.19.1",
    "@typescript-eslint/parser": "^8.19.1",
    "browser-sync": "^3.0.2",
    "browserslist": "^4.24.4",
    "eslint": "9.8.0",
    "eslint-plugin-storybook": "^0.9.0",
    "eslint-plugin-unused-imports": "^4.1.4",
    "fantasticon": "1.2.3",
    "fs-extra": "^11.1.1",
    "jasmine-core": "5.5.0",
    "jasmine-spec-reporter": "7.0.0",
    "karma": "6.4.0",
    "karma-chrome-launcher": "3.2.0",
    "karma-coverage": "^2.2.0",
    "karma-jasmine": "5.1.0",
    "karma-jasmine-html-reporter": "2.1.0",
    "karma-teamcity-reporter": "^1.1.0",
    "source-map-explorer": "2.5.3",
    "source-map-support": "0.5.21",
    "standard-version": "9.5.0",
    "storybook": "^8.3.3",
    "stylelint": "^16.8.1",
    "stylelint-config-standard": "^36.0.1",
    "stylelint-config-standard-scss": "^13.1.0",
    "stylelint-scss": "^6.5.0",
    "tinify": "1.7.1",
    "typescript": "~5.7.2"
  }
}
